/* tslint:disable:no-console */
import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { jssPreset } from '@material-ui/styles';
import { create } from 'jss';
import React from 'react';
import { JssProvider } from 'react-jss';
import { Router } from 'react-router';
import getTheme from 'shared/theme/custom';
import jssVendorPrefixer from 'utils/jssVendorPrefix';
import NavigationUtils from 'utils/NavigationUtils';
import AppLayout from './AppLayout';

const jss = create({ plugins: [...jssPreset().plugins, jssVendorPrefixer()] });

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <JssProvider jss={jss} classNamePrefix={'az-'}>
        <MuiThemeProvider theme={getTheme()}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={NavigationUtils.setupRouter()}>
              <React.Fragment>
                <CssBaseline />
                <AppLayout />
              </React.Fragment>
            </Router>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}

export default App;
