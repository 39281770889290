import { createBrowserHistory, History } from 'history';

let router: History;

const setupRouter = () => {
  router = createBrowserHistory({ basename: `/FFMNN/` });
  router.listen((newLocation, action) => {
    if (action === 'POP') {
      if (newLocation.pathname.includes('login')) {
        return;
      }
    }
  });
  return router;
};

const push = (path: string, state?: any) => {
  router.push(path, state);
};

const replace = (path: string, state?: any) => {
  router.replace(path, state);
};

const reload = () => {
  router.go(0);
};

const currentRoute = () => router.location.pathname;

const currentState = () => (router.location ? router.location.state : null);

export default {
  setupRouter,
  push,
  replace,
  reload,
  currentRoute,
  currentState
};
