import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface ICustomRouteProps extends RouteProps {
  routes?: RouteProps[];
}

const CustomRoute: React.FunctionComponent<ICustomRouteProps> = ({ component: Component, routes, path, ...rest }) => {
  // convert the nested route configs in to <Route />
  const renderProps = props => {
    return (
      // @ts-ignore
      <Component {...props} />
    );
  };

  return <Route {...rest} render={renderProps} />;
};

export default CustomRoute;
