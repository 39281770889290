import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flexGrow: 1,
      position: 'relative',
      minWidth: '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    page: {
      background: 'transparent',
      flex: '1 0 auto',
      minWidth: '100%',
      padding: 0,
      margin: 0,
      flexGrow: 1,
      order: 1,
      minHeight: '50%'
    },
    background: {
      position: 'fixed',
      top: 0,
      left: 0,
      minWidth: '100%',
      minHeight: '100%',
      backgroundSize: 'cover',
      [theme.breakpoints.up('md')]: {
        backgroundImage: 'url(' + require('../../../img/header-background-3-x@3x.png') + ')'
      },
      [theme.breakpoints.only('sm')]: {
        backgroundImage: 'url(' + require('../../../img/header-background-3-x@2x.png') + ')'
      },
      [theme.breakpoints.only('xs')]: {
        backgroundImage: 'url(' + require('../../../img/header-background-3-x.png') + ')'
      },
      backgroundRepeat: 'no-repeat'
    },
    gameNotFound: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      margin: 'auto',
      backgroundRepeat: 'no-repeat',
      left: 0,
      backgroundSize: 'contain',
      width: '20%',
      top: '35%',
      [theme.breakpoints.between('lg', 'xl')]: {
        backgroundImage: 'url(' + require('../../../img/game-not-found@3x.png') + ')'
      },
      [theme.breakpoints.only('md')]: {
        backgroundImage: 'url(' + require('../../../img/game-not-found@2x.png') + ')',
        width: '50%',
        top: '35%'
      },
      [theme.breakpoints.only('sm')]: {
        backgroundImage: 'url(' + require('../../../img/game-not-found@2x.png') + ')',
        width: '50%',
        top: '35%'
      },
      [theme.breakpoints.only('xs')]: {
        width: '50%',
        top: '35%',
        backgroundImage: 'url(' + require('../../../img/game-not-found.png') + ')'
      }
    },
    message: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      margin: 'auto',
      backgroundRepeat: 'no-repeat',
      top: '45%',
      left: 0,
      backgroundSize: 'contain',
      width: '70%',
      [theme.breakpoints.between('lg', 'xl')]: {
        backgroundImage: 'url(' + require('../../../img/message@3x.png') + ')',
        width: '40%'
      },
      [theme.breakpoints.only('md')]: {
        backgroundImage: 'url(' + require('../../../img/message@2x.png') + ')'
      },
      [theme.breakpoints.only('sm')]: {
        backgroundImage: 'url(' + require('../../../img/message@2x.png') + ')'
      },
      [theme.breakpoints.only('xs')]: {
        backgroundImage: 'url(' + require('../../../img/message.png') + ')'
      }
    }
  });

export default styles;
