import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import translations from './locales/en/translation.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(backend)
  .init({
    resources: {
      en: {
        translation: translations
      }
    },
    lng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true,
      transSupportBasicHtmlNodes: true
    }
  });

export default i18n;
