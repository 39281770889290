import hyphenate from 'hyphenate-style-name';
import { prefix } from 'inline-style-prefixer';
import { Plugin, Rule, Styles, StyleSheet } from 'jss';

export default (): Plugin => {
  const onProcessStyle = (style: Styles, rule: Rule, _1: StyleSheet): any => {
    // @ts-ignore
    if (rule.type !== 'style') {
      return style;
    }

    for (const prop of Object.keys(style)) {
      const value = style[prop];

      const inLineStyle = {
        [prop]: value
      };
      const output = prefix(inLineStyle);
      if (!!output && typeof output === 'object' && Object.keys(output).length > 0) {
        delete style[prop];
        Object.keys(output).forEach(key => {
          if (prop === 'display' && (value === 'flex' || value === 'inline-flex')) {
            // @ts-ignore
            style.display = output[key].join(';display:');
          } else {
            style[hyphenate(key)] = output[key];
          }
        });
      }
    }
    return style;
  };

  return {
    // @ts-ignore
    onProcessStyle
  };
};
