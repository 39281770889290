import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/modules/app/AppLayoutStyle';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface INotFoundScreenProps extends RouteComponentProps, WithStyles<typeof styles> {}

class NotFoundScreen extends React.Component<INotFoundScreenProps> {
  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.background}>
        <div className={classes.gameNotFound} />
        <div className={classes.message} />`
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(NotFoundScreen));
