import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/modules/app/AppLayoutStyle';
import React, { Fragment } from 'react';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import indexRoutes from 'routes';
import CustomRoute from 'shared/components/routes/CustomRoute';

interface IProps extends RouteComponentProps, WithStyles<typeof styles> {}

class AppLayout extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <Fragment>
        <div id="pageScrollTarget" className={this.props.classes.root}>
          <div className={this.props.classes.page}>
            <Switch>
              {indexRoutes.map((prop, key) => (
                <CustomRoute key={key} {...prop} />
              ))}
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(styles)(AppLayout)
);
