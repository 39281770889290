import color from 'color';

const themeColors: {
  primaryColor: color;
  secondaryColor: color;
  warningColor: color;
  dangerColor: color;
  successColor: color;
  infoColor: color;
  roseColor: color;
  grayColor: color;
  white: color;
  black: color;
  cornerColors: {
    activity: color;
    activityContrast: color;
    reward: color;
    rewardContrast: color;
    promotion: color;
    promotionContrast: color;
    game: color;
    gameContrast: color;
  };
} = {
  primaryColor: color('#0A548B'),
  secondaryColor: color('#FEC524'),
  warningColor: color('#F8C83E'),
  dangerColor: color('#FF4832'),
  successColor: color('#6AD359'),
  infoColor: color('#00acc1'),
  roseColor: color('#e91e63'),
  grayColor: color('#6E7C8D'),
  white: color('#ffffff'),
  black: color('#000000'),
  cornerColors: {
    activity: color('#0A548B'),
    activityContrast: color('#FFFFFF'),
    reward: color('#FECC36'),
    rewardContrast: color('#000000'),
    promotion: color('#FFFFFF'),
    promotionContrast: color('#0A548B'),
    game: color('#36B1E6'),
    gameContrast: color('#000000')
  }
};

export default {
  themeColors
};
